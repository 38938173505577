import { gql } from "@apollo/client";

import { SetUserPermissionsMutation, SetUserPermissionsMutationVariables } from "@/gql";
import { USER_PERMISSIONS_FRAGMENT } from "@/graphql/fragments/user-permissions.fragment";

import { makeGqlMutationHook } from "../util";

const SET_USER_PERMISSIONS = gql`
    ${USER_PERMISSIONS_FRAGMENT}

    mutation setUserPermissions(
        $gameId: String!
        $permissions: [Permission!]! = []
        $userId: String!
    ) {
        result: setUserPermissions(gameId: $gameId, permissions: $permissions, userId: $userId) {
            ...UserPermission
        }
    }
`;

export const useSetUserPermissionsMutation = makeGqlMutationHook<
    SetUserPermissionsMutation,
    SetUserPermissionsMutationVariables
>(SET_USER_PERMISSIONS);
