import { gql } from "@apollo/client";

import { CloneEntityMutation, CloneEntityMutationVariables } from "@/gql";

import { ENTITY_TABLE_DATA_FRAGMENT } from "../fragments/entity-table-data.fragment";
import { makeGqlMutationHook } from "../util";

const SCHEMA = gql`
    ${ENTITY_TABLE_DATA_FRAGMENT}

    mutation cloneEntity($guid: String!, $gameId: String!, $count: Float!) {
        result: cloneTemplateEntity(guid: $guid, gameId: $gameId, count: $count) {
            ...EntityTableData
        }
    }
`;

export const useCloneEntityMutation = makeGqlMutationHook<
    CloneEntityMutation,
    CloneEntityMutationVariables
>(SCHEMA);
