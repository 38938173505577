import { gql } from "@apollo/client";

import { SuggestUsersQuery, SuggestUsersQueryVariables } from "@/gql";
import { USER_FRAGMENT } from "@/graphql/fragments/user.fragment";

import { makeGqlQueryLazyHook } from "../util";

const SUGGEST_USER = gql`
    ${USER_FRAGMENT}

    query suggestUsers($gameId: String!, $query: String!) {
        result: suggestUsers(gameId: $gameId, query: $query) {
            ...User
        }
    }
`;

export const useSuggestUsers = makeGqlQueryLazyHook<SuggestUsersQuery, SuggestUsersQueryVariables>(
    SUGGEST_USER,
);
