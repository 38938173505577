import { gql } from "@apollo/client";

import { GetGameQuery, GetGameQueryVariables } from "@/gql";

import { makeGqlQueryLazyHook } from "../util";

export const GET_GAME = gql`
    query getGame($gameId: String!) {
        result: getGame(gameId: $gameId) {
            guid
            name
            description
            allowSyncFromOrigin
            sourceGameId
            creatorId
            meta {
                deployFlags
            }
            templates {
                guid
                gameId
                name
                description
                type
                lockedBy
                lockedAt
                lockReason
            }
            consumers {
                guid
                name
                description
            }
        }
    }
`;

export const useGetGameQuery = makeGqlQueryLazyHook<GetGameQuery, GetGameQueryVariables>(GET_GAME, {
    fetchPolicy: "no-cache",
});
