import { gql } from "@apollo/client";

import { PermissionsChangedSubscription, PermissionsChangedSubscriptionVariables } from "@/gql";

import { makeGqlSubscriptionHook } from "../util";

const PERMISSIONS_CHANGED = gql`
    subscription permissionsChanged {
        result: permissionsChanged {
            userId
            gameId
            permissions {
                permissions
                gameId
                userId
            }
        }
    }
`;

export const usePermissionsChangedSubscription = makeGqlSubscriptionHook<
    PermissionsChangedSubscription,
    PermissionsChangedSubscriptionVariables
>(PERMISSIONS_CHANGED);
