import { gql } from "@apollo/client";

import { LockTemplateMutation, LockTemplateMutationVariables } from "@/gql";

import { TEMPLATE_FRAGMENT } from "../fragments/template.fragment";
import { makeGqlMutationHook } from "../util";

const SCHEMA = gql`
    ${TEMPLATE_FRAGMENT}

    mutation lockTemplate($gameId: String!, $templateId: String!, $reason: String!) {
        lockTemplate(gameId: $gameId, templateId: $templateId, reason: $reason) {
            ...Template
        }
    }
`;

export const useLockTemplate = makeGqlMutationHook<
    LockTemplateMutation,
    LockTemplateMutationVariables
>(SCHEMA);
