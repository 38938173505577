import { CellContext } from "@tanstack/react-table";
import { DateDisplay } from "lk-web/components";
import { useMemo } from "react";

import { JobModel, Maybe } from "@/gql";

export function DateCell(props: CellContext<JobModel, Maybe<number> | undefined>) {
    const finishedOn = useMemo(() => new Date(props.getValue() ?? 0), [props]);

    return finishedOn ? (
        <span className="small">
            <DateDisplay date={finishedOn} format="DD MMM YYYY HH:mm:ss" />
        </span>
    ) : (
        <span>Never</span>
    );
}
