import { useRouter } from "@tanstack/react-router";
import { useCallback } from "react";
import Button from "react-bootstrap/Button";

export function Page404() {
    const router = useRouter();
    const goBack = useCallback(() => router.history.back(), [router]);

    return (
        <div className="jumbotron">
            <h1 className="display-4">Page not found</h1>
            <p className="lead">%generic page 404 text%</p>
            <Button onClick={goBack}>Go back</Button>
        </div>
    );
}

export default Page404;
