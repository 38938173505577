import { ApolloError } from "@apollo/client";
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";

import { useAppDispatch } from "@/hooks";
import { CLEAR_GAMES_ERROR, START_GAMES_LOADING, STOP_GAMES_LOADING } from "@/store/games/slice";
import { AppDispatch } from "@/store/index";

type WrappedAction<Variables, Result = void> = (
    dispatch: AppDispatch,
    variables: Variables,
) => Promise<Result>;

type WrappedActionTuple<Variables, Result> = [
    (data: Variables) => Promise<Result>,
    { error?: Error | ApolloError; loading: boolean },
];

export function useWrapAction<Variables, Result = void>(
    action: WrappedAction<Variables, Result>,
): WrappedActionTuple<Variables, Result> {
    const callbackRef = useRef(action);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | ApolloError>();

    useLayoutEffect(() => {
        callbackRef.current = action;
    });

    const callback = useCallback(
        async (data: Variables) => {
            try {
                setLoading(true);
                setError(undefined);
                dispatch(CLEAR_GAMES_ERROR());
                dispatch(START_GAMES_LOADING());
                const result = await callbackRef.current(dispatch, data);
                return result;
            } catch (e) {
                setError(e as Error);
                throw e;
            } finally {
                setLoading(false);
                dispatch(STOP_GAMES_LOADING());
            }
        },
        [dispatch],
    );

    return useMemo(() => [callback, { loading, error }], [callback, error, loading]);
}
