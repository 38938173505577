import { gql } from "@apollo/client";

import { GetJobsQuery, GetJobsQueryVariables } from "@/gql";
import { JOB_FRAGMENT } from "@/graphql/fragments/job.fragment";
import { USER_FRAGMENT } from "@/graphql/fragments/user.fragment";
import { makeGqlQueryHook } from "@/graphql/util";

const SCHEMA = gql`
    ${JOB_FRAGMENT}
    ${USER_FRAGMENT}

    query getJobs($eventTypes: [QueueType!]!) {
        getJobs(eventTypes: $eventTypes) {
            ...Job
            user {
                ...User
            }
        }
    }
`;

export const useGetJobs = makeGqlQueryHook<GetJobsQuery, GetJobsQueryVariables>(SCHEMA, {
    fetchPolicy: "no-cache",
});
