import { gql } from "@apollo/client";

import { DeleteTemplateCategoryMutation, DeleteTemplateCategoryMutationVariables } from "@/gql";
import { makeGqlMutationHook } from "@/graphql/util";

const schema = gql`
    mutation deleteTemplateCategory($gameId: String!, $guid: String!) {
        deleteCategory(gameId: $gameId, guid: $guid) {
            isOk
            info
        }
    }
`;

export const useDeleteTemplateCategory = makeGqlMutationHook<
    DeleteTemplateCategoryMutation,
    DeleteTemplateCategoryMutationVariables
>(schema);
