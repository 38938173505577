import { gql } from "@apollo/client";

import { ImportFromSpreadsheetMutation, ImportFromSpreadsheetMutationVariables } from "@/gql";
import { JOB_FRAGMENT } from "@/graphql/fragments/job.fragment";
import { makeGqlMutationHook } from "@/graphql/util";

const SCHEMA = gql`
    ${JOB_FRAGMENT}

    mutation importFromSpreadsheet(
        $gameId: String!
        $templateId: String!
        $spreadsheetId: String!
    ) {
        importFromSpreadsheet(
            gameId: $gameId
            templateId: $templateId
            spreadsheetId: $spreadsheetId
        ) {
            ...Job
        }
    }
`;

export const useImportFromSpreadsheet = makeGqlMutationHook<
    ImportFromSpreadsheetMutation,
    ImportFromSpreadsheetMutationVariables
>(SCHEMA);
