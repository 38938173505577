import { gql } from "@apollo/client";

import { SetProjectDeployFlagsMutation, SetProjectDeployFlagsMutationVariables } from "@/gql";
import { makeGqlMutationHook } from "@/graphql/util";

const SCHEMA = gql`
    mutation setProjectDeployFlags($gameId: String!, $flags: [String!]!) {
        setDeployFlags(gameId: $gameId, flags: $flags) {
            name
            value
            description
        }
    }
`;

export const useSetProjectDeployFlags = makeGqlMutationHook<
    SetProjectDeployFlagsMutation,
    SetProjectDeployFlagsMutationVariables
>(SCHEMA);
