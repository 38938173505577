import { createAction, createReducer } from "@reduxjs/toolkit";
import keyBy from "lodash/keyBy";
import { useEffect } from "react";

import {
    GameFragment,
    GetGameQuery,
    Identifier,
    PermissionsChangedSubscription,
    TemplateDisplay,
    TemplateParam,
    UserPermission,
} from "@/gql";
import { useAppSelector } from "@/hooks";
import { RootState } from "@/store";
import { Template } from "@/types";

import { useLoadGameDetailsAction } from "./actions";

interface GamesSlice {
    gameDetails: GetGameQuery["result"];
    isLoading: boolean;
    error: Error | null;
    templatesMap: Record<string, Template>;
}

const initialState: () => GamesSlice = () => ({
    gameDetails: {
        allowSyncFromOrigin: false,
        sourceGameId: "",
        guid: "",
        description: "",
        creatorId: "",
        // creator: {
        //     guid: "",
        //     firstname: "",
        //     lastname: "",
        //     email: "",
        //     canDoWizardry: false,
        //     isDisabled: false,
        // },
        meta: {
            deployFlags: [],
        },
        // deployLock: null,
        name: "",
        // userPermissions: [],
        templates: [],
        templateParams: [],
        globalParams: [],
        consumers: [],
        // updatedAt: null,
        // createdAt: new Date().toISOString(),
        // templateDisplay: [],
    },
    templatesMap: {},
    isLoading: false,
    error: null,
});

export const SET_GAMES = createAction<GameFragment[]>("SET_GAMES");
export const SET_GAME_DETAILS = createAction<GetGameQuery["result"]>("SET_GAME_DETAILS");
export const START_GAMES_LOADING = createAction("START_GAMES_LOADING");
export const STOP_GAMES_LOADING = createAction("STOP_GAMES_LOADING");
export const SET_GAMES_ERROR = createAction<Error>("SET_GAMES_ERROR");
export const CLEAR_GAMES_ERROR = createAction("CLEAR_GAMES_ERROR");
export const CREATE_TEMPLATE = createAction<Template>("CREATE_TEMPLATE");
export const UPDATE_TEMPLATE = createAction<Template>("UPDATE_TEMPLATE");
export const REMOVE_TEMPLATE = createAction<{ guid: string }>("REMOVE_TEMPLATE");
export const CREATE_TEMPLATE_PARAM = createAction<TemplateParam>("CREATE_TEMPLATE_PARAM");
export const REMOVE_TEMPLATE_PARAM = createAction<Identifier & { templateId?: string }>(
    "REMOVE_TEMPLATE_PARAM",
);
export const UPDATE_TEMPLATE_DISPLAY = createAction<TemplateDisplay>("UPDATE_TEMPLATE_DISPLAY");
export const UPDATE_TEMPLATE_PARAM = createAction<TemplateParam>("UPDATE_TEMPLATE_PARAMS");
export const UPDATE_USER_PERMISSIONS =
    createAction<PermissionsChangedSubscription["result"]>("UPDATE_USER_PERMISSIONS");
export const ADD_USER_PERMISSIONS = createAction<UserPermission>("ADD_USER_PERMISSIONS");
export const SET_USER_PERMISSIONS = createAction<UserPermission>("SET_USER_PERMISSIONS");
export const REMOVE_USER_PERMISSIONS =
    createAction<Pick<UserPermission, "userId" | "gameId">>("REMOVE_USER_PERMISSIONS");

export const gamesSlice = createReducer<GamesSlice>(initialState, (builder) => {
    builder
        .addCase(START_GAMES_LOADING, (state) => {
            state.isLoading = true;
        })
        .addCase(STOP_GAMES_LOADING, (state) => {
            state.isLoading = false;
        })
        .addCase(SET_GAMES_ERROR, (state, action) => {
            state.error = action.payload;
        })
        .addCase(CLEAR_GAMES_ERROR, (state) => {
            state.error = null;
        })
        .addCase(SET_GAME_DETAILS, (state, action) => {
            state.gameDetails = action.payload;
            state.templatesMap = keyBy(action.payload.templates, "guid");
        })
        .addCase(CREATE_TEMPLATE, (state, action) => {
            const newTemplate = action.payload;

            if (state.gameDetails) {
                state.gameDetails.templates = [...state.gameDetails.templates, newTemplate];
            }

            state.templatesMap[newTemplate.guid] = newTemplate;
        })
        .addCase(UPDATE_TEMPLATE, (state, action) => {
            const updatedTemplate = action.payload;

            if (state.gameDetails) {
                state.gameDetails.templates = state.gameDetails.templates.map((template) => {
                    return template.guid === updatedTemplate.guid ? updatedTemplate : template;
                });
            }

            state.templatesMap[updatedTemplate.guid] = updatedTemplate;
        })
        .addCase(REMOVE_TEMPLATE, (state, action) => {
            const { guid } = action.payload;

            if (state.gameDetails) {
                state.gameDetails.templates = state.gameDetails.templates.filter(
                    (template) => template.guid !== guid,
                );
            }

            delete state.templatesMap[guid];
        });
});

export const gamesSelector = (state: RootState) => state.gamesSlice;

export function useGameDetails(gameId?: string) {
    const [loadGameDetailsAction] = useLoadGameDetailsAction();

    if (!gameId)
        throw new Error(`Could not use ${useGameDetails.name}. Argument "gameId" is undefined`);

    useEffect(() => {
        loadGameDetailsAction({ gameId: gameId });
    }, [gameId]);

    return useAppSelector((state) => {
        return state.gamesSlice.gameDetails;
    });
}
