import { gql } from "@apollo/client";

import { GetReferencingParametersQuery, GetReferencingParametersQueryVariables } from "@/gql";
import { TEMPLATE_SHORT_FRAGMENT } from "@/graphql/fragments/template.fragment";
import { TEMPLATE_PARAM_SHORT_FRAGMENT } from "@/graphql/fragments/template-param.fragment";

import { makeGqlQueryHook } from "../util";

const SCHEMA = gql`
    ${TEMPLATE_SHORT_FRAGMENT}
    ${TEMPLATE_PARAM_SHORT_FRAGMENT}

    query getReferencingParameters($gameId: String!, $templateId: String!) {
        referencingParameters: getReferencingParameters(gameId: $gameId, templateId: $templateId) {
            ...TemplateParamShort
            template {
                ...TemplateShort
            }
        }
    }
`;

export const useGetReferencingParameters = makeGqlQueryHook<
    GetReferencingParametersQuery,
    GetReferencingParametersQueryVariables
>(SCHEMA);
