import { gql } from "@apollo/client";

import { CONSUMER_FRAGMENT } from "./consumer.fragment";

export const TEMPLATE_PARAM_META = gql`
    fragment TemplateParamMeta on TemplateParamMeta {
        defaultValue
        displayName
        enum {
            bitMask
            enumValues {
                value
                name
            }
        }
        enumId
        isGlobal
        isRequired
        isProtected
        isUnique
        templateId
        scriptScopes
        scriptScope
        display {
            editEntityOrder
            editEntityWidth
            entitiesTableOrder
            referenceDisplayOrder
        }
    }
`;

export const TEMPLATE_PARAM_SHORT_FRAGMENT = gql`
    fragment TemplateParamShort on TemplateParam {
        guid
        gameId
        name
        description
        templateId
    }
`;

export const TEMPLATE_PARAM_FRAGMENT = gql`
    ${TEMPLATE_PARAM_META}

    fragment TemplateParam on TemplateParam {
        guid
        gameId
        name
        description
        isGlobal
        type
        subType
        templateId
        meta {
            ...TemplateParamMeta
        }
    }
`;

export const TEMPLATE_PARAM_DETAILED_FRAGMENT = gql`
    ${TEMPLATE_PARAM_FRAGMENT}
    ${CONSUMER_FRAGMENT}

    fragment TemplateParamDetailed on TemplateParam {
        ...TemplateParam
        consumers {
            ...Consumer
        }
    }
`;
