import { gql } from "@apollo/client";

import { RemoveTemplateMutation, RemoveTemplateMutationVariables } from "@/gql";

import { IDENTIFIER_FRAGMENT } from "../fragments/identifier.fragment";
import { makeGqlMutationHook } from "../util";

const REMOVE_TEMPLATE = gql`
    ${IDENTIFIER_FRAGMENT}

    mutation removeTemplate($guid: String!, $gameId: String!) {
        result: removeTemplate(guid: $guid, gameId: $gameId) {
            ...Identifier
        }
    }
`;

export const useRemoveTemplateMutation = makeGqlMutationHook<
    RemoveTemplateMutation,
    RemoveTemplateMutationVariables
>(REMOVE_TEMPLATE);
