import { gql } from "@apollo/client";

import { CreateTemplateCategoryMutation, CreateTemplateCategoryMutationVariables } from "@/gql";
import { TEMPLATE_FRAGMENT } from "@/graphql/fragments/template.fragment";
import { TEMPLATE_CATEGORY_FRAGMENT } from "@/graphql/fragments/template-category.fragment";
import { makeGqlMutationHook } from "@/graphql/util";

const schema = gql`
    ${TEMPLATE_CATEGORY_FRAGMENT}
    ${TEMPLATE_FRAGMENT}

    mutation createTemplateCategory($name: String!, $gameId: String!) {
        createCategory(name: $name, gameId: $gameId) {
            ...TemplateCategory
            templates {
                ...Template
            }
        }
    }
`;

export const useCreateTemplateCategory = makeGqlMutationHook<
    CreateTemplateCategoryMutation,
    CreateTemplateCategoryMutationVariables
>(schema);
