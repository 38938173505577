import { IconButton } from "lk-web/components";
import { useContext } from "react";
import { AccordionContext, useAccordionButton } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { AccordionButtonProps } from "react-bootstrap/AccordionButton";
import AccordionItemContext from "react-bootstrap/AccordionItemContext";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import styled from "styled-components";

const StyledAccordionButton = styled(Accordion.Button)`
    &:after {
        display: none;
    }
`;

export function CustomAccordionHeader(props: AccordionButtonProps) {
    const { children, onClick } = props;
    const { activeEventKey } = useContext(AccordionContext);
    const { eventKey } = useContext(AccordionItemContext);

    const decoratedOnClick = useAccordionButton(eventKey, onClick);

    const isCurrentEventKey = Array.isArray(activeEventKey)
        ? activeEventKey.includes(eventKey)
        : activeEventKey === eventKey;

    return (
        <StyledAccordionButton className="px-3 py-2">
            <IconButton
                variant="secondary"
                className="p-1 me-2"
                icon={isCurrentEventKey ? FaCaretDown : FaCaretUp}
                onClick={decoratedOnClick}
            />
            {children}
        </StyledAccordionButton>
    );
}
