import { gql } from "@apollo/client";

import { PromoteJobMutation, PromoteJobMutationVariables } from "@/gql";
import { JOB_FRAGMENT } from "@/graphql/fragments/job.fragment";
import { makeGqlMutationHook } from "@/graphql/util";

const SCHEMA = gql`
    ${JOB_FRAGMENT}

    mutation promoteJob($jobId: String!, $queueType: QueueType!) {
        promoteJob(jobId: $jobId, queueType: $queueType) {
            ...Job
        }
    }
`;

export const usePromoteJob = makeGqlMutationHook<PromoteJobMutation, PromoteJobMutationVariables>(
    SCHEMA,
);
