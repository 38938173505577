import groupBy from "lodash/groupBy";
import { ReactNode, useCallback, useMemo } from "react";
import Accordion from "react-bootstrap/Accordion";
import { AccordionSelectCallback } from "react-bootstrap/AccordionContext";
import { useSessionStorage } from "usehooks-ts";

import { JobsGroup } from "@/components/common/JobsTable/JobsGroup";
import { JobModel } from "@/gql";

type Props = {
    jobs: JobModel[];
    actionsColumn?: (row: JobModel) => ReactNode[] | ReactNode;
};

const EXPANDED_JOBS_KEY = "jobs-list-expanded";

export function JobsAccordion(props: Props) {
    const jobGroups = useMemo(() => groupBy(props.jobs, "name"), [props.jobs]);
    const [selectedJobTypes, setSelectedJobTypes] = useSessionStorage<string[]>(
        EXPANDED_JOBS_KEY,
        [],
    );
    const onChange = useCallback<AccordionSelectCallback>(
        (eventKey) => {
            let newValue: string[] = [];

            if (eventKey !== null && eventKey !== undefined) {
                newValue = newValue.concat(eventKey);
            }

            setSelectedJobTypes(newValue);
        },
        [setSelectedJobTypes],
    );

    return (
        <Accordion defaultActiveKey={selectedJobTypes} onSelect={onChange} alwaysOpen>
            {Object.entries(jobGroups).map(([key, jobGroup]) => (
                <JobsGroup name={key} key={key} groupKey={key} jobs={jobGroup} />
            ))}
        </Accordion>
    );
}
