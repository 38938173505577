import { gql } from "@apollo/client";

import { CreateEntityMutation, CreateEntityMutationVariables } from "@/gql";

import { ENTITY_TABLE_DATA_FRAGMENT } from "../fragments/entity-table-data.fragment";
import { makeGqlMutationHook } from "../util";

const CREATE_ENTITY = gql`
    ${ENTITY_TABLE_DATA_FRAGMENT}

    mutation createEntity(
        $gameId: String!
        $templateEntityParams: [CreateTemplateEntityParamDto!]!
        $templateId: String!
    ) {
        result: createTemplateEntity(
            gameId: $gameId
            templateEntityParams: $templateEntityParams
            templateId: $templateId
        ) {
            ...EntityTableData
        }
    }
`;

export const useCreateEntityMutation = makeGqlMutationHook<
    CreateEntityMutation,
    CreateEntityMutationVariables
>(CREATE_ENTITY);
