import { rootRoute } from "@/pages/__root";
import {
    artifactsDiffRoute,
    artifactsHistoryRoute,
    artifactsListRoute,
    artifactsRoute,
    assetsRoute,
    blocklyRoute,
    categoriesRoute,
    consumersRoute,
    deployDirectoriesRoute,
    deployFlagsRoute,
    deployLocksRoute,
    entitiesRoute,
    entityRoute,
    exportTemplateToSpreadsheetRoute,
    forbiddenRoute,
    gameRoute,
    gamesRoute,
    globalParamsRoute,
    glossariesRoute,
    importRoute,
    importTemplateFromSpreadsheetRoute,
    indexRoute,
    localesRoute,
    loginRoute,
    pandorasBoxRoute,
    passwordResetRoute,
    passwordResetSetNewPasswordRoute,
    permissionsRoute,
    profileRoute,
    projectHistoryRoute,
    projectIndexRoute,
    projectRoute,
    templateDisplayRoute,
    templateParametersRoute,
    templateRoute,
    templatesRoute,
    templateStructureRoute,
    translationRoute,
    translationsRoute,
    wizardryGamesRoute,
    wizardryRoute,
    wizardryUsersRoute,
} from "@/routes";

export const routeTree = rootRoute.addChildren([
    passwordResetRoute,
    passwordResetSetNewPasswordRoute,
    indexRoute.addChildren([
        loginRoute,
        forbiddenRoute,
        gamesRoute,
        profileRoute,
        wizardryRoute.addChildren([
            wizardryGamesRoute,
            wizardryUsersRoute,
            pandorasBoxRoute,
            deployLocksRoute,
        ]),
        gameRoute.addChildren([
            templatesRoute,
            entityRoute,
            importRoute,
            categoriesRoute,
            consumersRoute,
            assetsRoute,
            globalParamsRoute,
            blocklyRoute,
            translationRoute.addChildren([translationsRoute, localesRoute, glossariesRoute]),
            projectHistoryRoute,
            artifactsRoute.addChildren([
                artifactsListRoute,
                artifactsHistoryRoute,
                artifactsDiffRoute,
            ]),
            projectRoute.addChildren([
                projectIndexRoute,
                deployFlagsRoute,
                permissionsRoute,
                deployDirectoriesRoute,
            ]),
            templateRoute.addChildren([
                entitiesRoute,
                templateStructureRoute,
                templateParametersRoute,
                templateDisplayRoute,
                exportTemplateToSpreadsheetRoute,
                importTemplateFromSpreadsheetRoute,
            ]),
        ]),
    ]),
]);
