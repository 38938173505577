import { gql } from "@apollo/client";

import { ImportEntitiesFromMutation, ImportEntitiesFromMutationVariables } from "@/gql";
import { makeGqlMutationHook } from "@/graphql/util";

import { JOB_FRAGMENT } from "../fragments/job.fragment";

const SCHEMA = gql`
    ${JOB_FRAGMENT}

    mutation importEntitiesFrom(
        $targetGameId: String!
        $sourceGameId: String!
        $templateIds: [String!]!
    ) {
        importEntitiesFrom(
            targetGameId: $targetGameId
            sourceGameId: $sourceGameId
            templateIds: $templateIds
        ) {
            ...Job
        }
    }
`;

export const useImportEntitiesFrom = makeGqlMutationHook<
    ImportEntitiesFromMutation,
    ImportEntitiesFromMutationVariables
>(SCHEMA);
