import { gql } from "@apollo/client";

import { TemplateCategoryQuery, TemplateCategoryQueryVariables } from "@/gql";
import { TEMPLATE_CATEGORY_FRAGMENT } from "@/graphql/fragments/template-category.fragment";
import { makeGqlQueryHook } from "@/graphql/util";

const schema = gql`
    ${TEMPLATE_CATEGORY_FRAGMENT}

    query templateCategory($gameId: String!, $guid: String!) {
        templateCategory(gameId: $gameId, guid: $guid) {
            ...TemplateCategory
        }
    }
`;

export const useTemplateCategory = makeGqlQueryHook<
    TemplateCategoryQuery,
    TemplateCategoryQueryVariables
>(schema);
