import { ColumnFiltersState, getFilteredRowModel, getSortedRowModel } from "@tanstack/react-table";
import { Table } from "lk-web/components";
import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";

import { JobModel } from "@/gql";

import { AccordionBody } from "./components/AccordionBody";
import { CustomAccordionHeader } from "./components/CustomAccordionHeader";
import { JobsTableExpandedRowComponent } from "./components/JobsTableExpandedRowComponent";
import { columns } from "./constants";

type Props = {
    name: string;
    groupKey: string;
    jobs: JobModel[];
};

export function JobsGroup(props: Props) {
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]); // can set initial column filter state here

    return (
        <Accordion.Item eventKey={props.groupKey}>
            <CustomAccordionHeader>{props.groupKey}</CustomAccordionHeader>
            <AccordionBody>
                <Table
                    tableOptions={{
                        state: {
                            columnFilters,
                        },
                        onColumnFiltersChange: setColumnFilters,
                        enableSorting: true,
                        getFilteredRowModel: getFilteredRowModel(),
                        getSortedRowModel: getSortedRowModel(),
                    }}
                    data={props.jobs}
                    columns={columns}
                    expandableRowsComponent={JobsTableExpandedRowComponent}
                />
            </AccordionBody>
        </Accordion.Item>
    );
}
