import "./App.scss";

import { Matches } from "@tanstack/react-router";
import compose from "compose-function";

import {
    withErrorBoundary,
    withGql,
    withHelmet,
    withNiceModal,
    withRedux,
    withRouter,
    withSnackbar,
} from "./providers";

const App = () => <Matches />;

export default compose(
    withRouter,
    withErrorBoundary,
    withRedux,
    withGql,
    withHelmet,
    withNiceModal,
    withSnackbar,
)(App);
