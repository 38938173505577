import { gql } from "@apollo/client";

import { GetEntityForEditV2Query, GetEntityForEditV2QueryVariables } from "@/gql";
import { makeGqlQueryHook, makeGqlQueryLazyHook } from "@/graphql/util";

import { TEMPLATE_PARAM_META } from "../fragments/template-param.fragment";

const SCHEMA = gql`
    ${TEMPLATE_PARAM_META}

    query getEntityForEditV2($guid: String, $gameId: String!, $templateId: String!) {
        entity: entityV2(guid: $guid, gameId: $gameId) {
            guid
            gameId
            export
            values {
                parameterId
                shouldOmit
                value
            }
        }
        template(gameId: $gameId, guid: $templateId) {
            guid
            type
            templateParams {
                guid
                type
                subType
                meta {
                    ...TemplateParamMeta
                }
            }
        }
    }
`;

export const useGetEntityForEditV2 = makeGqlQueryHook<
    GetEntityForEditV2Query,
    GetEntityForEditV2QueryVariables
>(SCHEMA);
export const useLazyGetEntityForEditV2 = makeGqlQueryLazyHook<
    GetEntityForEditV2Query,
    GetEntityForEditV2QueryVariables
>(SCHEMA);
