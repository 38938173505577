import { gql } from "@apollo/client";

import { GetProjectPermissionsQuery, GetProjectPermissionsQueryVariables } from "@/gql";

import { USER_FRAGMENT } from "../fragments/user.fragment";
import { makeGqlQueryHook } from "../util";

const SCHEMA = gql`
    ${USER_FRAGMENT}

    query getProjectPermissions($gameId: String!) {
        projectPermissions(gameId: $gameId) {
            id
            gameId
            permissions
            userId
            isDisabled
            user {
                ...User
            }
        }
    }
`;

export const useGetProjectPermissions = makeGqlQueryHook<
    GetProjectPermissionsQuery,
    GetProjectPermissionsQueryVariables
>(SCHEMA);
