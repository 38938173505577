import { SnackbarProvider } from "notistack";
import { type ReactNode } from "react"

export function withSnackbar(component: () => ReactNode) {
    return () => <SnackbarProvider
        autoHideDuration={1500}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
    >
        {component()}
    </SnackbarProvider>
}