import { gql } from "@apollo/client";

import { GetTemplatesForImportQuery, GetTemplatesForImportQueryVariables } from "@/gql";
import { TEMPLATE_FRAGMENT } from "@/graphql/fragments/template.fragment";
import { TEMPLATE_PARAM_FRAGMENT } from "@/graphql/fragments/template-param.fragment";
import { makeGqlQueryHook } from "@/graphql/util";

const SCHEMA = gql`
    ${TEMPLATE_FRAGMENT}
    ${TEMPLATE_PARAM_FRAGMENT}

    query getTemplatesForImport($sourceGameId: String!, $targetGameId: String!) {
        getTemplatesForImport(sourceGameId: $sourceGameId, targetGameId: $targetGameId) {
            canBeImported
            matchErrors
            sourceMatch {
                ...Template
            }
            targetMatch {
                ...Template
            }
            templateParamsMatches {
                matchErrors
                sourceTemplateParam {
                    ...TemplateParam
                }
                targetTemplateParam {
                    ...TemplateParam
                }
            }
        }
    }
`;

export const useGetTemplatesForImport = makeGqlQueryHook<
    GetTemplatesForImportQuery,
    GetTemplatesForImportQueryVariables
>(SCHEMA);
