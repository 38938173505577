import { FaWandMagicSparkles } from "react-icons/fa6";

import { User as GqlUser } from "@/gql";

type User = Omit<GqlUser, "games" | "templateParams" | "templates" | "userPermissions">;

type Props = {
    user: User;
    className?: string;
};

export function UserInfo(props: Props) {
    return (
        <span className={props.className}>
            {props.user.firstname} {props.user.lastname} &lt;{props.user.email}&gt;{" "}
            {props.user.canDoWizardry && (
                <FaWandMagicSparkles size={18} style={{ color: "var(--bs-yellow)" }} />
            )}
        </span>
    );
}
