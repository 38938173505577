import { CellContext } from "@tanstack/react-table";
import { Variant } from "react-bootstrap/types";

import { CasedBadge } from "@/components/common/CasedBadge";
import { JobModel, JobStatus } from "@/gql";

const colors: Record<JobStatus, Variant> = {
    [JobStatus.Completed]: "success",
    [JobStatus.Failed]: "danger",
    [JobStatus.Active]: "primary",
    [JobStatus.Delayed]: "warning",
    [JobStatus.Stuck]: "warning",
    [JobStatus.Unknown]: "info",
    [JobStatus.Waiting]: "info",
    [JobStatus.Paused]: "info",
};

export function StatusCell(props: CellContext<JobModel, JobStatus>) {
    const status = props.getValue();

    return (
        <CasedBadge bg={colors[status] ?? "info"}>
            {status === JobStatus.Active ? props.row.original.progress : status}
        </CasedBadge>
    );
}
