import { gql } from "@apollo/client";

import { UpdateTemplateDisplayMutation, UpdateTemplateDisplayMutationVariables } from "@/gql";
import { makeGqlMutationHook } from "@/graphql/util";

const SCHEMA = gql`
    mutation updateTemplateDisplay(
        $gameId: String!
        $templateId: String!
        $fieldsOrder: [String!]!
        $refFieldsOrder: [String!]!
        $entitiesPerPage: Float!
        $defaultSortAttribute: String
        $defaultSortOrder: EntitiesSortOrder!
        $editEntityModalSettings: EditEntityModalSettings!
    ) {
        updateTemplateDisplay(
            gameId: $gameId
            templateId: $templateId
            fieldsOrder: $fieldsOrder
            entitiesPerPage: $entitiesPerPage
            defaultSortAttribute: $defaultSortAttribute
            defaultSortOrder: $defaultSortOrder
            refFieldsOrder: $refFieldsOrder
            editEntityModalSettings: $editEntityModalSettings
        ) {
            gameId
            templateId
            entitiesPerPage
            defaultSortAttribute
            defaultSortOrder
            fieldsOrder
            refFieldsOrder
        }
    }
`;

export const useUpdateTemplateDisplay = makeGqlMutationHook<
    UpdateTemplateDisplayMutation,
    UpdateTemplateDisplayMutationVariables
>(SCHEMA);
