import { gql } from "@apollo/client";

import { UnlockTemplateMutation, UnlockTemplateMutationVariables } from "@/gql";

import { TEMPLATE_FRAGMENT } from "../fragments/template.fragment";
import { makeGqlMutationHook } from "../util";

const SCHEMA = gql`
    ${TEMPLATE_FRAGMENT}

    mutation unlockTemplate($gameId: String!, $templateId: String!) {
        unlockTemplate(gameId: $gameId, templateId: $templateId) {
            ...Template
        }
    }
`;

export const useUnlockTemplate = makeGqlMutationHook<
    UnlockTemplateMutation,
    UnlockTemplateMutationVariables
>(SCHEMA);
