import { gql } from "@apollo/client";

import { GetAvailableTemplatesQuery, GetAvailableTemplatesQueryVariables } from "@/gql";

import { TEMPLATE_SHORT_FRAGMENT } from "../fragments/template.fragment";
import { makeGqlQueryHook } from "../util";

const SCHEMA = gql`
    ${TEMPLATE_SHORT_FRAGMENT}

    query getAvailableTemplates($gameId: String!) {
        templates(gameId: $gameId) {
            ...TemplateShort
            type
        }
    }
`;

export const useGetAvailableTemplates = makeGqlQueryHook<
    GetAvailableTemplatesQuery,
    GetAvailableTemplatesQueryVariables
>(SCHEMA);
