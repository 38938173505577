import { CellContext } from "@tanstack/react-table";

import { JobModel } from "@/gql";
import { stringify } from "@/utils/stringify";

export function JsonCell(props: CellContext<JobModel, any>) {
    const data = props.getValue();

    return data !== null ? <span className="small">{stringify(data)}</span> : null;
}
