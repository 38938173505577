import { gql } from "@apollo/client";

import { GamesQuery } from "@/gql";

import { GAME_FRAGMENT } from "../fragments/game.fragment";
import { makeGqlQueryHook } from "../util";

export const GET_GAMES = gql`
    ${GAME_FRAGMENT}
    query games {
        result: getGames {
            ...Game
        }
    }
`;

export const useGetGamesQuery = makeGqlQueryHook<GamesQuery>(GET_GAMES, {
    fetchPolicy: "no-cache",
});
