import { createRoute } from "@tanstack/react-router";
import { object } from "zod";

import { rootRoute } from "./pages/__root";
import { uuidValidator } from "./utils/validators/uuid";

export const passwordResetRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/password-reset",
}).lazy(() => import("@/pages/PasswordReset").then((d) => d.Route));

export const passwordResetSetNewPasswordRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/password-reset/$token",
}).lazy(() => import("@/pages/SetNewPasswordPage").then((d) => d.Route));

export const indexRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/",
}).lazy(() => import("@/pages/StartPage").then((d) => d.Route));

export const loginRoute = createRoute({
    getParentRoute: () => indexRoute,
    path: "/login",
}).lazy(() => import("@/pages/LoginPage").then((d) => d.Route));

export const forbiddenRoute = createRoute({
    getParentRoute: () => indexRoute,
    path: "/403",
}).lazy(() => import("@/pages/403").then((d) => d.Route));

export const gamesRoute = createRoute({
    getParentRoute: () => indexRoute,
    path: "/games",
}).lazy(() => import("@/pages/GamesPage").then((d) => d.Route));

export const profileRoute = createRoute({
    getParentRoute: () => indexRoute,
    path: "/profile",
}).lazy(() => import("@/pages/Profile").then((d) => d.Route));

export const wizardryRoute = createRoute({
    getParentRoute: () => indexRoute,
    path: "/wizardry",
}).lazy(() => import("@/pages/wizardry/WizardryLayout").then((d) => d.Route));

export const wizardryGamesRoute = createRoute({
    getParentRoute: () => wizardryRoute,
    path: "/games",
}).lazy(() => import("@/pages/wizardry/GamesWizardry").then((d) => d.Route));

export const wizardryUsersRoute = createRoute({
    getParentRoute: () => wizardryRoute,
    path: "/users",
}).lazy(() => import("@/pages/wizardry/UsersWizardry").then((d) => d.Route));

export const pandorasBoxRoute = createRoute({
    getParentRoute: () => wizardryRoute,
    path: "/pandoras-box",
}).lazy(() => import("@/pages/wizardry/PandorasBoxWizardry").then((d) => d.Route));

export const deployLocksRoute = createRoute({
    getParentRoute: () => wizardryRoute,
    path: "/deploy-locks",
}).lazy(() => import("@/pages/wizardry/DeployLocks").then((d) => d.Route));

export const gameRoute = createRoute({
    getParentRoute: () => indexRoute,
    path: "/$gameId",
}).lazy(() => import("@/pages/Game").then((d) => d.Route));

export const templatesRoute = createRoute({
    getParentRoute: () => gameRoute,
    path: "/templates",
}).lazy(() => import("@/pages/TemplatesList").then((d) => d.Route));

export const entityRoute = createRoute({
    getParentRoute: () => gameRoute,
    path: "/entity/$entityId",
}).lazy(() => import("@/pages/EntityPage").then((d) => d.Route));

export const importRoute = createRoute({
    getParentRoute: () => gameRoute,
    path: "/import",
}).lazy(() => import("@/pages/ImportPage").then((d) => d.Route));

export const categoriesRoute = createRoute({
    getParentRoute: () => gameRoute,
    path: "/categories",
}).lazy(() => import("@/pages/CategoriesPage").then((d) => d.Route));

export const templateRoute = createRoute({
    getParentRoute: () => gameRoute,
    path: "/templates/$templateId",
}).lazy(() => import("@/pages/template/Template").then((d) => d.Route));

export const consumersRoute = createRoute({
    getParentRoute: () => gameRoute,
    path: "/consumers",
}).lazy(() => import("@/pages/Consumers").then((d) => d.Route));

export const assetsRoute = createRoute({
    getParentRoute: () => gameRoute,
    path: "/assets",
}).lazy(() => import("@/pages/Assets").then((d) => d.Route));

export const globalParamsRoute = createRoute({
    getParentRoute: () => gameRoute,
    path: "/global-params",
}).lazy(() => import("@/pages/GlobalParams").then((d) => d.Route));

export const blocklyRoute = createRoute({
    getParentRoute: () => gameRoute,
    path: "/blockly",
}).lazy(() => import("@/pages/BlocklySandbox").then((d) => d.Route));

export const translationRoute = createRoute({
    getParentRoute: () => gameRoute,
    path: "/translation",
}).lazy(() => import("@/pages/translation/TranslationPageLayout").then((d) => d.Route));

export const translationsRoute = createRoute({
    getParentRoute: () => translationRoute,
    path: "/table",
}).lazy(() => import("@/pages/translation/TranslationsPage").then((d) => d.Route));

export const localesRoute = createRoute({
    getParentRoute: () => translationRoute,
    path: "/locales",
}).lazy(() => import("@/pages/translation/LocalesPage").then((d) => d.Route));

export const glossariesRoute = createRoute({
    getParentRoute: () => translationRoute,
    path: "/glossaries",
}).lazy(() => import("@/pages/translation/GlossariesPage").then((d) => d.Route));

export const projectHistoryRoute = createRoute({
    getParentRoute: () => gameRoute,
    path: "/history",
}).lazy(() => import("@/pages/ProjectHistory").then((d) => d.Route));

export const artifactsRoute = createRoute({
    getParentRoute: () => gameRoute,
    path: "/artifacts",
}).lazy(() => import("@/pages/Artifacts").then((d) => d.Route));

export const artifactsListRoute = createRoute({
    getParentRoute: () => artifactsRoute,
    path: "/",
}).lazy(() => import("@/pages/ArtifactsList").then((d) => d.Route));

export const artifactsHistoryRoute = createRoute({
    getParentRoute: () => artifactsRoute,
    path: "/history",
}).lazy(() => import("@/pages/ArtifactsHistory").then((d) => d.Route));

const validateSearch = object({
    a: uuidValidator().optional(),
    b: uuidValidator().optional(),
    selectedConsumerId: uuidValidator().optional(),
});

export const artifactsDiffRoute = createRoute({
    getParentRoute: () => artifactsRoute,
    path: "/diff",
    validateSearch,
}).lazy(() => import("@/pages/ArtifactsDiff").then((d) => d.Route));

export const projectRoute = createRoute({
    getParentRoute: () => gameRoute,
    path: "/project",
}).lazy(() => import("@/pages/project-settings/ProjectSettings").then((d) => d.Route));

export const projectIndexRoute = createRoute({
    getParentRoute: () => projectRoute,
    path: "/",
}).lazy(() => import("@/pages/project-settings/ProjectSettingsPage").then((d) => d.Route));

export const deployFlagsRoute = createRoute({
    getParentRoute: () => projectRoute,
    path: "/deploy-flags",
}).lazy(() => import("@/pages/project-settings/DeployFlagsPage").then((d) => d.Route));

export const permissionsRoute = createRoute({
    getParentRoute: () => projectRoute,
    path: "/permissions",
}).lazy(() => import("@/pages/project-settings/PermissionsPage").then((d) => d.Route));

export const deployDirectoriesRoute = createRoute({
    getParentRoute: () => projectRoute,
    path: "/deploy-directories",
}).lazy(() => import("@/pages/project-settings/DeployDirectories").then((d) => d.Route));

export const entitiesRoute = createRoute({
    getParentRoute: () => templateRoute,
    path: "/entities",
}).lazy(() => import("@/pages/EntitiesList").then((d) => d.Route));

export const templateStructureRoute = createRoute({
    getParentRoute: () => templateRoute,
    path: "/structure",
}).lazy(() => import("@/pages/template/TemplateStructure").then((d) => d.Route));

export const templateParametersRoute = createRoute({
    getParentRoute: () => templateRoute,
    path: "params",
}).lazy(() => import("@/pages/template/TemplateParameters").then((d) => d.Route));

export const templateDisplayRoute = createRoute({
    getParentRoute: () => templateRoute,
    path: "display",
}).lazy(() => import("@/pages/template/TemplateDisplay").then((d) => d.Route));

export const exportTemplateToSpreadsheetRoute = createRoute({
    getParentRoute: () => templateRoute,
    path: "export",
}).lazy(() => import("@/pages/template/TemplateExportToSpreadsheet").then((d) => d.Route));

export const importTemplateFromSpreadsheetRoute = createRoute({
    getParentRoute: () => templateRoute,
    path: "import",
}).lazy(() => import("@/pages/template/TemplateImportFromSpreadsheet").then((d) => d.Route));
