import { gql } from "@apollo/client";

import { GetTemplateDisplayQuery, GetTemplateDisplayQueryVariables } from "@/gql";
import { TEMPLATE_FRAGMENT } from "@/graphql/fragments/template.fragment";
import { TEMPLATE_PARAM_FRAGMENT } from "@/graphql/fragments/template-param.fragment";
import { makeGqlQueryHook } from "@/graphql/util";

const SCHEMA = gql`
    ${TEMPLATE_FRAGMENT}
    ${TEMPLATE_PARAM_FRAGMENT}

    query getTemplateDisplay($templateId: String!, $gameId: String!) {
        templateDisplay(templateId: $templateId, gameId: $gameId) {
            gameId
            templateId
            entitiesPerPage
            defaultSortAttribute
            defaultSortOrder
            fieldsOrder
            refFieldsOrder
        }
        template(guid: $templateId, gameId: $gameId) {
            ...Template
            templateParams {
                ...TemplateParam
            }
        }
    }
`;

export const useGetTemplateDisplay = makeGqlQueryHook<
    GetTemplateDisplayQuery,
    GetTemplateDisplayQueryVariables
>(SCHEMA);
