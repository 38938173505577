import jsonStableStringify from "json-stable-stringify";

export function stringify(data: any, ...fieldsToOmit: string[]) {
    return JSON.stringify(
        data,
        function (key, value) {
            if (fieldsToOmit.includes(key)) return;
            return value;
        },
        2,
    );
}

export function stableStringify(data: any, options?: jsonStableStringify.StableStringifyOptions) {
    return jsonStableStringify(data, options);
}
