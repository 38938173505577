import { EntityTableData } from "@/gql";
import { OperableEntityTableData, OperableTemplateEntity, TemplateEntity } from "@/types";

export function entityToOperableEntity(entity: TemplateEntity): OperableTemplateEntity {
    return {
        ...entity,
        entityParameters: entity.entityParameters ?? [],
        values: Object.fromEntries(
            entity.entityParameters?.map((param) => [param.templateParameterId, param.value]) ?? [],
        ),
        enabledState: Object.fromEntries(
            entity.entityParameters?.map((param) => [
                param.templateParameterId,
                param.shouldOmit,
            ]) ?? [],
        ),
    };
}

export function operableEntityToEntity(opEntity: OperableTemplateEntity): TemplateEntity {
    const { values, ...entity } = opEntity;

    return {
        ...entity,
        entityParameters: entity.entityParameters.map((parameter) => ({
            ...parameter,
            value: values[parameter.templateParameterId],
        })),
    };
}

export function entityTableDataToOperable(entity: EntityTableData): OperableEntityTableData {
    return {
        ...entity,
        enabledState: Object.fromEntries(
            entity.values?.map((param) => [param.parameterId, param.shouldOmit]) ?? [],
        ),
        values: Object.fromEntries(
            entity.values?.map((param) => [param.parameterId, param.value]) ?? [],
        ),
    };
}
