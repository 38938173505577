import { gql } from "@apollo/client";

import { DeployLockLiftedSubscription, DeployLockLiftedSubscriptionVariables } from "@/gql";

import { DEPLOY_LOCK_FRAGMENT } from "../fragments/deploy-lock.fragment";
import { USER_FRAGMENT } from "../fragments/user.fragment";
import { makeGqlSubscriptionHook } from "../util";

export const useDeployLockLiftedSubscription = makeGqlSubscriptionHook<
    DeployLockLiftedSubscription,
    DeployLockLiftedSubscriptionVariables
>(gql`
    ${DEPLOY_LOCK_FRAGMENT}
    ${USER_FRAGMENT}

    subscription deployLockLifted($gameId: String!) {
        deployLockLifted(gameId: $gameId) {
            ...DeployLock
            creator {
                ...User
            }
        }
    }
`);
