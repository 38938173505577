import { type ReactNode } from "react"
import { Provider as ReduxProvider } from "react-redux";

import { store } from "../../store";

export function withRedux(component: () => ReactNode) {
    return () => <ReduxProvider store={store}>
        {component()}
    </ReduxProvider>
}
