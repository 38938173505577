import isNil from "lodash/isNil";
import { useMemo } from "react";
import Stack from "react-bootstrap/Stack";

import { JobModel } from "@/gql";
import { stringify } from "@/utils/stringify";

type Props = {
    data: JobModel;
};

export function JobsTableExpandedRowComponent(props: Props) {
    const isFailed = props.data.failedReason || props.data.stacktrace.length > 0;
    const failedReason =
        props.data.failedReason !== "" && !isNil(props.data.failedReason)
            ? props.data.failedReason
            : "unknown";
    const jobData = useMemo(() => stringify(props.data.data), [props.data.data]);

    return (
        <Stack gap={2} className="p-4">
            <div>
                <h5>Job data</h5>
                <pre>{jobData}</pre>
            </div>
            <hr className="my-1" />
            {isFailed && (
                <div>
                    <h5>Error</h5>
                    <p>Reason: {failedReason}</p>
                    {props.data.stacktrace.length > 0 && (
                        <pre>{props.data.stacktrace.join("\n")}</pre>
                    )}
                </div>
            )}
        </Stack>
    );
}
