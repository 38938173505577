import { gql } from "@apollo/client";

import { GetEntityForEditQuery, GetEntityForEditQueryVariables } from "@/gql";
import { ENTITY_FRAGMENT } from "@/graphql/fragments/entity.fragment";
import { ENTITY_PARAM_FRAGMENT } from "@/graphql/fragments/entity-param.fragment";
import { TEMPLATE_FRAGMENT } from "@/graphql/fragments/template.fragment";
import { TEMPLATE_PARAM_FRAGMENT } from "@/graphql/fragments/template-param.fragment";
import { makeGqlQueryHook, makeGqlQueryLazyHook } from "@/graphql/util";

const SCHEMA = gql`
    ${ENTITY_FRAGMENT}
    ${ENTITY_PARAM_FRAGMENT}
    ${TEMPLATE_FRAGMENT}
    ${TEMPLATE_PARAM_FRAGMENT}

    query getEntityForEdit($guid: String, $gameId: String!, $templateId: String!) {
        entity(guid: $guid, gameId: $gameId) {
            ...EntityFragment
            entityParameters {
                ...EntityParamFragment
            }
        }
        template(gameId: $gameId, guid: $templateId) {
            ...Template
            templateParams {
                ...TemplateParam
            }
        }
    }
`;

export const useGetEntityForEdit = makeGqlQueryHook<
    GetEntityForEditQuery,
    GetEntityForEditQueryVariables
>(SCHEMA);
export const useLazyGetEntityForEdit = makeGqlQueryLazyHook<
    GetEntityForEditQuery,
    GetEntityForEditQueryVariables
>(SCHEMA);
